import _ from "lodash";
import { universeFunc } from "../../components/RecipientDetailsModal";
import { titleCase } from "change-case";
import {
  fieldBankDetails,
  fieldRecipientDetails,
} from "./single/recipient/add/constant";
import { FieldBoolean } from "./single/recipient/add/boolean";

export const isObjectStrict = (val) =>
  typeof val === "object" && !Array.isArray(val);

const userNameGenerator = (data) => {
  const receivingRegisteredName = universeFunc(
    "receiving_registered_name",
    data
  );
  const registeredName = universeFunc("registered_name", data);
  const receivingFirstName = universeFunc("receiving_first_name", data);
  const receivingLastName = universeFunc("receiving_last_name", data);
  const firstName = universeFunc("firstname", data);
  const lastName = universeFunc("lastname", data);
  const userName =
    receivingRegisteredName ||
    registeredName ||
    `${receivingFirstName || firstName} ${receivingLastName || lastName}`;

  return userName || false;
};

export const UniverseDataGenerator = ({
  data,
  t = () => {},
  isBahasa = false,
  startCase = false,
}) => {
  const FunctionTitleCase = (child) =>
    startCase ? _.startCase(child) : _.capitalize(child);
  const CapitalizeTranslation = (key) => FunctionTitleCase(t(titleCase(key)));
  const userName = userNameGenerator(data);

  const universeData = data?.user_detail?.universe_data || {};
  const personalInformationArray = [];
  const bankInformationArray = [];

  const titleDecider = (key) => {
    const { isCompanySocialCreditCode, isSwiftBIC, isIfsCode, isMSISDN } =
      FieldBoolean(key);

    if (isCompanySocialCreditCode)
      return t("Company Social Credit Code (USCC)");
    if (isSwiftBIC) return t("SWIFT/BIC code");
    if (isIfsCode) return t("IFS Code");
    if (isMSISDN) return t("Phone Number")

    return CapitalizeTranslation(key);
  };

  if (userName) {
    personalInformationArray.push({
      title: CapitalizeTranslation("Name"),
      value: userName,
    });
  }

  Object.keys(universeData).forEach((key) => {
    const {
      isBankId,
      isUserName,
      isNationalityIsoCode,
      isCountryIsoCode,
      isBranchNumber,
      isMSISDN,
    } = FieldBoolean(key);
    const skipRender =
      isBankId || isUserName || isNationalityIsoCode || isCountryIsoCode;

    if (skipRender) return;

    const data = {
      title: titleDecider(key),
      value: universeData[key],
    };

    const dataMSISDN = {
      title: titleDecider(key),
      value: universeData["MSISDN"] || universeData["msisdn"],
    };

    const dataBranchNumber = {
      title: titleDecider(key),
      value: universeData[key]?.branch_code,
    };

    if (fieldRecipientDetails?.includes(key) && universeData[key]) {
      if (isMSISDN) return personalInformationArray.push(dataMSISDN);
      return personalInformationArray.push(data);
    }
    if (fieldBankDetails?.includes(key) && universeData[key]) {
      if (isBranchNumber && isObjectStrict(universeData[key])) {
        return bankInformationArray.push(dataBranchNumber);
      }
      return bankInformationArray.push(data);
    }
  });

  const universeDataCombine = [
    ...personalInformationArray,
    ...bankInformationArray,
  ];

  return {
    universeData: universeDataCombine,
    bankInformationArray,
    personalInformationArray,
  };
};
