import * as Sentry from "@sentry/nextjs";
import { isProduction } from "@tools";
import { get, isEmpty } from "lodash";
import { useRouter } from "next/router";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFetch } from "tools/api";
import { decryptToken, encryptToken } from "tools/api/functions/hash";
import { useModalHook } from "../components/Modals";
import { booleanState } from "../components/Status";
import { apiBusiness, useMutation } from "../tools/api";
import { logoutEvents, setUserEvents } from "../universalFunctions/events";
import { TeamJack } from "./ConstantsContext/parent";
import { userFormatter } from "./logics/authContext/formatter";
import {
  loginNotRequiredPathnames,
  usePageRedirectMaster,
  useRedirectAfterLogin,
} from "./logics/authContext/pageRedirect";
import {
  subscriptionFormatter,
  useSubscription,
} from "./logics/authContext/subscription";
import { ToasterHook } from "./ToasterContext";
import { useFingerprinting } from "tools/api/functions/fingerprinting";
import { IntegrationObj } from "pageComponents/integrationsComponents/components.types";

export type Subscription = {
  account_status?: string;
  max_physical_cards?: number;
  max_users?: number;
  max_virtual_cards?: number;
  plan_type?: string;
  total_physical_cards?: number;
  total_users?: number;
  total_virtual_cards?: number;
  due_date?: string;
  invoice_qty_left?: number;
  reimbursement_qty_left?: number;
  max_invoice_free_fee?: number;
  max_reimbursement_free_fee?: number;
  [other: string]: unknown;
};

type MyAccountRole = {
  name?: string;
  [other: string]: unknown;
};
type MyAccountAllPermissionActionType = {
  action?: string;
  model_name?: string;
};
type MyAccountAllPermission = {
  all_action_types?: MyAccountAllPermissionActionType[];
  module_code?: string;
};
type MyAccountPartnerCardBusinessType = {
  card_type?: string;
  [other: string]: unknown;
};
type MyAccountPartnerCardBusiness = {
  card_business_types?: MyAccountPartnerCardBusinessType[];
  [other: string]: unknown;
};
type MyAccountPartnerDisbursementBalance = {
  [other: string]: unknown;
};
type MyAccountTnc = {
  user_card?: string;
  user_remittance?: string;
  virtual_account?: string;
  [other: string]: unknown;
};
type MyAccountTncConfig = {
  user_card?: string;
  user_remittance?: string;
  virtual_account?: string;
  [other: string]: unknown;
};
type MyAccountPartnerActiveServices = {
  international_transfer?: boolean;
  local_transfer?: boolean;
  bill_payment?: boolean;
  reimbursement?: boolean;
  payroll?: boolean;
  card?: boolean;
  topup?: boolean;
  va_collection?: boolean;
};
type MyAccountPartner = {
  active_integrations?: IntegrationObj[];
  document_state?: string;
  card_business?: MyAccountPartnerCardBusiness;
  all_flags?: string[];
  hide_beta_feature?: string[];
  disbursement_balance?: MyAccountPartnerDisbursementBalance[];
  active_services?: MyAccountPartnerActiveServices;
  [other: string]: unknown;
};
export type MyAccount = {
  id?: number;
  name?: string;
  email?: string;
  team_id?: number | null;
  isDeleted?: boolean;
  partner?: MyAccountPartner;
  roles?: MyAccountRole[];
  all_permissions?: MyAccountAllPermission[];
  subscription?: Subscription;
  card?: { [other: string]: unknown };
  all_tncs?: MyAccountTnc;
  tnc_configs?: MyAccountTncConfig;
  activePathnames?: string[];
  buttonBooleans?: { [other: string]: boolean };
  is_agreed?: boolean;
  is_verified?: boolean;
  logoutTime?: number;
  all_flags?: string[];
  managed_teams?: TeamJack[];
  pin?: string;
  role?: string;
  [other: string]: unknown;
};

export type MyAccountFetchData = {
  data?: MyAccount;
};

type AuthValue = {
  authorize?: (args: {
    token?: string;
    setIsLoggedIn?: Dispatch<SetStateAction<boolean>>;
    tempPassword?: string;
    handleRedirect?: () => void;
  }) => void;
  authorizeAgreed?: (args: { is_agreed: boolean }) => void;
  unauthorize?: () => void;
  user?: MyAccount;
  userHasApiKey?: boolean;
  isDemoUser?: boolean;
  userLoading?: boolean;
  refetchUser?: () => Promise<void>;
  tempPassword?: string | boolean;
  resetTempPassword?: () => void;
  subscription?: MyAccount["subscription"];
};

export const userStatusBoolean = (status?: string) => {
  const isInReview = status == "in_review";
  const isPending = status == "pending";
  const isNotSubmitted = status == "not_submitted";
  const isWaitingVerification = status == "waiting_verification";
  const showSubmitVerificationButton = isNotSubmitted || isPending;
  const isCompleted =
    Boolean(status) &&
    !isPending &&
    !isInReview &&
    !isNotSubmitted &&
    !isWaitingVerification;
  return {
    isCompleted,
    isInReview,
    isPending,
    isNotSubmitted,
    isWaitingVerification,
    showSubmitVerificationButton,
    status,
  };
};

export const localUserStatusBoolean = (user?: MyAccount) => {
  const status = get(
    user,
    "partner.document_state",
    ""
  ) as MyAccountPartner["document_state"];
  const booleans = userStatusBoolean(status);
  return booleans;
};

export const defaultButtons = {
  canViewAccount: false,
  canViewActivity: false,
  canSendMoney: false,
  canViewRecipients: false,
  canSeeVA: false,
  canCreateVA: false,
  canInviteUser: false,
  canCreateCard: false,
  canUpdateCard: false,
  canBlockCard: false,
  canFreezeCard: false,
  canUnfreezeCard: false,
  canUnmaskCard: false,
  canViewAllCards: false,
  isUltimate: false,
};

export const defaultButtonsReversed = {
  canViewAccount: true,
  canViewActivity: true,
  canSendMoney: true,
  canViewRecipients: true,
  canSeeVA: true,
  canCreateVA: true,
  canInviteUser: true,
  canCreateCard: true,
  canUpdateCard: true,
  canBlockCard: true,
  canFreezeCard: true,
  canUnfreezeCard: true,
  canUnmaskCard: true,
  canViewAllCards: true,
  isUltimate: true,
};

export const LSUserDeletion = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("showed_suspend_or_overdue_modal");
  localStorage.removeItem("pin-remaining-attempt");
  localStorage.removeItem("OTL");
  localStorage.removeItem("salus");
  localStorage.removeItem("castle");
  localStorage.removeItem("danamonLastFourDigits");
  localStorage.removeItem("visitorId");
  sessionStorage.removeItem("HAS_SEEN_MODAL_VAT_ONCE_LOGIN");
};

const getSubscription = async () => {
  const { data: business_usage } = await apiBusiness.get(
    "/subscription_module/business_usage"
  );

  return subscriptionFormatter(business_usage?.data);
};

const additionalFetch = async () => {
  const { data: card } = await apiBusiness.get("/card_business_detail");

  const subscription = await getSubscription();

  return {
    card: card?.data,
    subscription,
  };
};

const formatter = (data: MyAccountFetchData, secondData: MyAccount) => {
  const { card, subscription } = secondData ?? {};

  const newData = data?.data?.partner?.card_business ?? {};

  const partner = {
    ...(data?.data?.partner || {}),
    card_business: { ...newData, ...card },
  };

  const realUser = { ...data?.data, partner, subscription };
  const user = userFormatter(realUser || {});

  localStorage.setItem("user", encryptToken(JSON.stringify(user)));
  return user;
};

export const AuthContext = createContext<AuthValue>({});

export const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const {
    data: user,
    setData: setUser,
    refetch: refetchUser,
    loading: userLoading,
  } = useFetch<MyAccount | Record<string, any>>({
    url: "/my_account",
    woInit: true,
    additionalFetch,
    formatter: (data, _, secondData) => formatter(data, secondData ?? {}),
    defaultValue: {},
    noToaster: false,
  });

  const setsUser = (user: MyAccount) => {
    setUser(user);
    localStorage.setItem("user", encryptToken(JSON.stringify(user)));
  };

  const subscription = useSubscription({
    ...user?.subscription,
    refetch: async () => {
      const subscription = await getSubscription();
      setUser((user) => {
        const refetch = user?.subscription?.refetch;
        return {
          ...user,
          subscription: { ...subscription, refetch },
        };
      });
    },
    loadingSubscriptionUsage: userLoading,
  });

  const { pathname, push } = useRouter();

  useEffect(() => {
    const isExcluded = loginNotRequiredPathnames.includes(pathname);
    if (isExcluded) return;

    if (
      localStorage.getItem("user") ||
      pathname == "/_error" ||
      pathname.includes("kyb-jack")
    )
      return localStorage.setItem("pathnameStorage", "");

    localStorage.setItem("pathnameStorage", pathname);
  }, [pathname]);

  const { redirectAfterLogin } = useRedirectAfterLogin();

  usePageRedirectMaster({
    user,
  });

  const { email, partner } = user || {};

  const userHasApiKey = Boolean(partner?.live_api_key);

  // const isWelcomeModalOpen = !is_agreed;

  const isDemoUser = email == "transfezdemo@gmail.com";

  const authorizeAgreed = async ({ is_agreed }: { is_agreed: boolean }) => {
    const result = { ...user, is_agreed };
    setsUser(result);
  };

  const unauthorize = () => {
    LSUserDeletion();
    setUser({});
    isProduction && Sentry.configureScope((scope) => scope.setUser(null));
    logoutEvents(user);
  };

  const { errorToaster, warningToaster } = ToasterHook();

  const [tempPassword, setTempPassword] = useState<boolean | string>(false);

  const { isOpen, toggle } = useModalHook();

  const { getData } = useFingerprinting();

  const authorize = async ({
    token,
    setIsLoggedIn,
    tempPassword,
    handleRedirect,
  }: {
    token?: string;
    setIsLoggedIn?: Dispatch<SetStateAction<boolean>>;
    tempPassword?: string;
    handleRedirect?: () => void;
  }) => {
    try {
      //sets token
      const encryptedToken = encryptToken(token ?? "");
      localStorage.setItem("token", encryptedToken);
      //sets token

      //sets user
      const { data } = await apiBusiness.get("/my_account");

      const isAgreedNone = !data?.data?.is_agreed;

      if (isAgreedNone) {
        const { secretKey, ...res } = await getData();
        await apiBusiness.put("/update_account", {
          is_agreed: true,
          ...res,
        });
      }

      const user = userFormatter(data?.data || {});

      const { email, is_verified } = user;

      if (!is_verified) {
        setIsLoggedIn && setIsLoggedIn(false);
        unauthorize();
        if (errorToaster) {
          errorToaster(
            "Error!",
            `Please check your email ${email} to access Jack`
          );
        }
        return;
      }

      setsUser(user);
      //sets user

      setTempPassword(tempPassword ?? "");

      const storageKey = "hasOpenedNew";
      const hasOpened = localStorage.getItem(storageKey);
      !hasOpened && toggle();
      // localStorage.setItem(storageKey, "true");

      localStorage.setItem("popup", "true");
      localStorage.removeItem("OTL");

      if (handleRedirect) return handleRedirect();

      redirectAfterLogin(user);
      setUserEvents(user);
    } catch (err) {
      return "error";
      console.log("err:", err);
    }
  };

  useEffect(() => {
    const userRaw = localStorage.getItem("user");
    if (userRaw) {
      try {
        const user = JSON.parse(decryptToken(userRaw));
        setUser(user);
      } catch (error) {
        console.log("error:", error);
      }
    }
  }, []);

  const { isWaitingVerification } = userStatusBoolean(
    user?.partner?.document_state
  );

  const stopperPathnames = [
    "/",
    "/login",
    "/create-password",
    "/register",
    "/dashboard",
    "/new-register",
  ];
  const noRefetch = stopperPathnames.includes(pathname);
  // used in KYB so it can updates user state after user submits data

  useEffect(() => {
    if (!isWaitingVerification) return;
    if (noRefetch) return;

    let interval = setInterval(async () => refetchUser(), 5000);
    return () => clearInterval(interval);
  }, [isWaitingVerification]);
  // used in KYB

  useEffect(() => {
    if (isEmpty(user)) return;
    // needs setTimeout coz for some reason, moengage not ready yet if u call directly
    setTimeout(() => setUserEvents(user), 2000);
  }, [isEmpty(user)]);

  const { logoutTime } = user ?? {};

  useEffect(() => {
    if (!logoutTime) return;

    let interval = setInterval(async () => {
      const now = new Date().getTime();
      if (now > logoutTime) {
        unauthorize();
        push("/login");
        if (warningToaster) {
          warningToaster({
            msg: "Your session is expired, please login again",
          });
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [logoutTime]);
  // auto logout timeout handler

  // refetch every change page
  useEffect(() => {
    const isRegister =
      pathname.includes("/register") || pathname.includes("/new-register");
    if (noRefetch || isRegister) return;
    refetchUser();
  }, [pathname]);
  // refetch every change page

  return (
    <AuthContext.Provider
      value={{
        authorize,
        authorizeAgreed,
        unauthorize,
        user,
        userHasApiKey,
        isDemoUser,
        userLoading,
        refetchUser,
        tempPassword,
        resetTempPassword: () => setTempPassword(false),
        subscription,
      }}
    >
      {children}
      {/* <WelcomeModalJack isOpen={isOpen} toggle={toggle} /> */}
    </AuthContext.Provider>
  );
};

export const useGetAuth = () => {
  const {
    authorize,
    authorizeAgreed,
    unauthorize,
    user,
    userHasApiKey,
    isDemoUser,
    userLoading,
    refetchUser,
    tempPassword,
    resetTempPassword,
  } = useContext(AuthContext) ?? {};
  return {
    authorize,
    authorizeAgreed,
    unauthorize,
    user,
    userHasApiKey,
    isDemoUser,
    userLoading,
    refetchUser,
    tempPassword,
    resetTempPassword,
  };
};

export const useUserFlags = (props = { woAutoRefetchUser: false }) => {
  const { woAutoRefetchUser } = props;

  const { user, userLoading, refetchUser } = useGetAuth();

  const userId = user?.id;
  const userFlags = user?.all_flags ?? [];

  const { mutation: addUserFlag, loading: isLoadingAddUserFlag } = useMutation<{
    flags: string;
  }>({
    method: "post",
    url: `/business_users/${userId}/create_user_flag`,
    afterSuccess: () => {
      if (woAutoRefetchUser) return;
      if (refetchUser) {
        refetchUser();
      }
    },
  });

  const handleAddUserFlag = async (flag: string) => {
    const isExist = userFlags.includes(flag);
    const canAddUserFlag = !isExist && userId;
    if (!canAddUserFlag) return;

    await addUserFlag({ flags: flag });
  };

  const stopper = !!isEmpty(user) || isLoadingAddUserFlag || userLoading;

  return {
    stopper,
    userFlags,
    isLoadingUserFlags: userLoading,
    isLoadingAddUserFlag,
    hasUser: !isEmpty(user),
    addUserFlag: handleAddUserFlag,
  };
};

export const JACK_TRANSFER_VALUE = "/jack-transfer";
export const CROSS_BORDER_VALUE = "/cross-border";
export const LOCAL_TRANSFER_VALUE = "/local-transfer/create";
export const REIMBURSEMENT_VALUE = "/reimbursement/create?step=input-name";
export const BILL_VALUE = "/invoice-payment/create?step=upload";
export const PAYROLL_VALUE = "/payroll/create";
export const CARD_VALUE = "/cards/create";
export const VA_VALUE = "/virtual-account/create";
export const VA_SIDEBAR_VALUE = "Virtual Account";
export const TOPUP_VALUE = "topup";

export const INTERNATIONAL_AUTH_ENUM = "international";
export const LOCAL_AUTH_ENUM = "local";
export const BILL_AUTH_ENUM = "bill";
export const REIMBURSEMENT_AUTH_ENUM = "reimbursement";
export const PAYROLL_AUTH_ENUM = "payroll";
export const CARD_AUTH_ENUM = "card";
export const TOPUP_AUTH_ENUM = "topup";
export const VA_AUTH_ENUM = "va";

export const useActiveModules = (
  args = { isOpenCreateDropdown: false, module: "" }
) => {
  const { isOpenCreateDropdown, module } = args ?? {};

  const { push } = useRouter();

  const { user } = useGetAuth();
  const { partner } = user ?? {};
  const { active_services } = partner ?? {};

  const {
    international_transfer,
    local_transfer,
    bill_payment,
    reimbursement,
    payroll,
    card,
    topup,
    va_collection,
  } = active_services ?? {};

  const activeModules = {
    isActiveInternationalTransfer: Boolean(international_transfer),
    isActiveLocalTransfer: Boolean(local_transfer),
    isActiveBillPayment: Boolean(bill_payment),
    isActiveReimbursement: Boolean(reimbursement),
    isActivePayroll: Boolean(payroll),
    isActiveCard: Boolean(card),
    isActiveTopup: Boolean(topup),
    isActiveVA: Boolean(va_collection),
  };

  const {
    isActiveInternationalTransfer,
    isActiveLocalTransfer,
    isActiveBillPayment,
    isActiveReimbursement,
    isActivePayroll,
    isActiveCard,
    isActiveTopup,
    isActiveVA,
  } = activeModules;

  const isDisabledReleaseDecider = ({ module = "" }) => {
    const {
      isActiveInternationalTransfer,
      isActiveLocalTransfer,
      isActiveReimbursement,
      isActiveBillPayment,
      isActivePayroll,
    } = activeModules ?? {};

    const {
      isCrossBorder,
      isLocalTransfer,
      isLocalDisbursementBatch,
      isLocalDisbursement,
      isLocalDisbursements,
      isLocalTransferSingle,
      isReimbursement,
      isInvoice,
      isInvoicePayment,
      isPayroll,
    } = booleanState(module);

    const isLocalTransferType =
      isLocalTransfer ||
      isLocalDisbursementBatch ||
      isLocalDisbursement ||
      isLocalDisbursements ||
      isLocalTransferSingle;
    const isBillType = isInvoice || isInvoicePayment;

    if (isCrossBorder && !isActiveInternationalTransfer) {
      return true;
    }
    if (isLocalTransferType && !isActiveLocalTransfer) {
      return true;
    }
    if (isReimbursement && !isActiveReimbursement) {
      return true;
    }
    if (isBillType && !isActiveBillPayment) {
      return true;
    }
    if (isPayroll && !isActivePayroll) {
      return true;
    }

    return false;
  };

  const [isAllowOverflow, setIsAllowOverflow] = useState(false);
  const [isTolerateUnhover, setIsTolerateUnhover] = useState(true);
  const [showInactiveModuleTooltip, setShowInactiveModuleTooltip] =
    useState("");

  const tolerateUnhover = () => setIsTolerateUnhover(true);
  const intolerateUnhover = () => setIsTolerateUnhover(false);
  const resetModuleTooltip = () => setShowInactiveModuleTooltip("");

  const handleUnhoverModuleOption = () => {
    tolerateUnhover();
  };

  const handleHoverModuleOption = (module = "") => {
    intolerateUnhover();
    if (module !== showInactiveModuleTooltip) resetModuleTooltip();

    const isInternationalTransfer = module === CROSS_BORDER_VALUE;
    const isLocalTransfer = module === LOCAL_TRANSFER_VALUE;
    const isReimbursement = module === REIMBURSEMENT_VALUE;
    const isBillPayment = module === BILL_VALUE;
    const isPayroll = module === PAYROLL_VALUE;
    const isCard = module === CARD_VALUE;
    const isVA = module === VA_VALUE;
    const isVASidebar = module === VA_SIDEBAR_VALUE;
    const isTopup = module === TOPUP_VALUE;

    const setModule = () => setShowInactiveModuleTooltip(module);

    if (isInternationalTransfer && !isActiveInternationalTransfer) {
      setModule();
      return;
    }
    if (isLocalTransfer && !isActiveLocalTransfer) {
      setModule();
      return;
    }
    if (isReimbursement && !isActiveReimbursement) {
      setModule();
      return;
    }
    if (isBillPayment && !isActiveBillPayment) {
      setModule();
      return;
    }
    if (isPayroll && !isActivePayroll) {
      setModule();
      return;
    }
    if (isCard && !isActiveCard) {
      setModule();
      return;
    }
    if (isVA && !isActiveVA) {
      setModule();
      return;
    }
    if (isVASidebar && !isActiveVA) {
      setModule();
      return;
    }
    if (isTopup && !isActiveTopup) {
      setModule();
      return;
    }
  };

  useEffect(() => {
    if (!isTolerateUnhover) return;
    const timeout = setTimeout(() => {
      resetModuleTooltip();
    }, 500);
    return () => clearTimeout(timeout);
  }, [isTolerateUnhover]);

  useEffect(() => {
    if (!isOpenCreateDropdown) {
      setIsAllowOverflow(false);
      return;
    }
    const timeout = setTimeout(() => {
      setIsAllowOverflow(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, [isOpenCreateDropdown]);

  useEffect(() => {
    const kick = () => push("/dashboard");

    switch (module) {
      case INTERNATIONAL_AUTH_ENUM:
        if (!isActiveInternationalTransfer) kick();
        break;
      case LOCAL_AUTH_ENUM:
        if (!isActiveLocalTransfer) kick();
        break;
      case BILL_AUTH_ENUM:
        if (!isActiveBillPayment) kick();
        break;
      case REIMBURSEMENT_AUTH_ENUM:
        if (!isActiveReimbursement) kick();
        break;
      case PAYROLL_AUTH_ENUM:
        if (!isActivePayroll) kick();
        break;
      case CARD_AUTH_ENUM:
        if (!isActiveCard) kick();
        break;
      case TOPUP_AUTH_ENUM:
        if (!isActiveTopup) kick();
        break;
      case VA_AUTH_ENUM:
        if (!isActiveVA) kick();
        break;
      default:
        break;
    }
  }, [
    module,
    isActiveInternationalTransfer,
    isActiveLocalTransfer,
    isActiveBillPayment,
    isActiveReimbursement,
    isActivePayroll,
    isActiveCard,
    isActiveTopup,
    isActiveVA,
  ]);

  return {
    ...activeModules,
    isDisabledReleaseDecider,
    isAllowOverflow,
    showInactiveModuleTooltip,
    handleHoverModuleOption,
    handleUnhoverModuleOption,
  };
};

export const getBusinessFlags = () => {
  const { user } = useGetAuth();
  const { all_flags = [] } = user?.partner || {};
  const isEWalletActivated = all_flags.includes("E_WALLET_ACTIVE");

  return { isEWalletActivated };
};

export const getUserStatusBoolean = () => {
  const { user } = useContext(AuthContext);
  const status = get(
    user,
    "partner.document_state",
    ""
  ) as MyAccountPartner["document_state"];
  const booleans = userStatusBoolean(status);
  return booleans;
};

export const getUserButtonBooleans = () => {
  const { user } = useContext(AuthContext);
  const buttonBooleans = user?.buttonBooleans || {};
  return buttonBooleans;
};

export const useInvalidStatus = () => {
  const { isCompleted } = getUserStatusBoolean();
  const { isOpen, toggle } = useModalHook();

  const invalidUserStatus = () => {
    if (!isCompleted) {
      toggle && toggle();
      return true;
    }
  };

  return { invalidUserStatus, isOpen, toggle };
};

export const getRolesUserLogin = (roles: MyAccountRole[]) => {
  if (!roles) return {};

  const filterer = (string: string): boolean =>
    roles.filter(({ name }) => (name ?? "").includes(string)).length > 0;

  const isDrafter = filterer("payroll_drafter");
  const isApprover = filterer("payroll_approver");
  const isAdmin = filterer("partner_admin");
  const isSuperAdmin = filterer("partner_super_admin");
  const isNotified = filterer("payroll_notified");
  const isPayroll = filterer("payroll");
  const isDrafterCard = filterer("card_drafter");
  const isDrafterSendMoney = filterer("sendmoney_drafter");
  const isDrafterPayroll = filterer("payroll_drafter");
  const isDrafterInvoice = filterer("invoice_drafter");
  const isDrafterReimbursement = filterer("reimbursement_drafter");
  const isDrafterBalanceManagement = filterer("balance_management_drafter");

  return {
    isDrafterSendMoney,
    isDrafterReimbursement,
    isDrafterInvoice,
    isDrafterCard,
    isDrafter,
    isApprover,
    isAdmin,
    isNotified,
    isSuperAdmin,
    isPayroll,
    isDrafterPayroll,
    isDrafterBalanceManagement,
  };
};

export const getUserRoleBooleans = ({
  roles,
  role,
  pin,
  managed_teams,
}: MyAccount) => {
  const {
    isAdmin: isAdminFromRoles,
    isSuperAdmin: isSuperAdminFromRoles,
    isApprover,
    isDrafter,
    isNotified,
    isDrafterInvoice,
    isPayroll,
    isDrafterReimbursement,
    isDrafterCard,
    isDrafterSendMoney,
    isDrafterPayroll,
    isDrafterBalanceManagement,
  } = getRolesUserLogin(roles || []);

  const isSuperAdmin = role === "partner_super_admin" || isSuperAdminFromRoles;
  const isAdmin = role === "partner_admin" || isAdminFromRoles;
  const isBookKeeper = role === "partner_viewer";
  const isEmployee = role === "partner_maker";
  const roleName = isSuperAdmin
    ? "Business Owner"
    : isAdmin
    ? "Admin"
    : isBookKeeper
    ? "Bookkeeper"
    : "Employee";
  const isHasPin = Boolean(pin);

  const isAdminOrSuperAdmin = isAdmin || isSuperAdmin;

  const isManagingTeams = (managed_teams ?? []).length > 0;

  const isEmployeeManager = isEmployee && isManagingTeams;
  const isBookKeeperManager = isBookKeeper && isManagingTeams;

  const isWeakManager = isEmployeeManager || isBookKeeperManager;

  return {
    isDrafterInvoice,
    isDrafterReimbursement,
    isDrafterCard,
    isSuperAdmin,
    isAdmin,
    isAdminOrSuperAdmin,
    isBookKeeper,
    isEmployee,
    isApprover,
    isDrafter,
    roleName,
    isHasPin,
    isNotified,
    isPayroll,
    isManagingTeams,
    isEmployeeManager,
    isBookKeeperManager,
    isWeakManager,
    managedTeams: managed_teams,
    isDrafterSendMoney,
    isDrafterPayroll,
    isDrafterBalanceManagement,
  };
};

export const getUserRole = () => {
  const { user } = useContext(AuthContext);

  return getUserRoleBooleans(user || {});
};
