import React from "react";
import styled from "styled-components";
import { jackColors } from "../assets/colors";
import { JackIcons } from "../assets/jackIcons/parent";
import bankIconDecider from "../constants/bankIconDecider";
import { GothamMedium, TypeFezNeueMedium } from "./Text";

const BankCard = ({ bank, bankName, onClick }) => {
  return (
    <BankDiv onClick={() => onClick({ type: "select_bank", value: bank })}>
      <div className="d-flex align-items-center">
        <img
          src={bankIconDecider(bankName)}
          style={{ width: 32, height: 32 }}
        />
        <GothamMedium style={{ marginLeft: 16 }}>{bankName}</GothamMedium>
      </div>
      <JackIcons name="chevron-right" fill="black" style={{ width: 20 }} />
    </BankDiv>
  );
};

export const BankDiv = styled.div`
  margin-top: 16px;
  padding: 12px;
  border: 1px solid #e6e6e8;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: ${jackColors.neutral400};
    transition: all 250ms ease-in-out;
  }
`;

export default BankCard;
